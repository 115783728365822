<template>
    <transition name="fade">
        <div id="J_head-search-res" class="head-search-res" :style="{
            top: placement.top,
            left: placement.left,
        }" @mouseleave="handlerMouseleave">
            <div class="search-history-wrapper">
                <h3 class="search-title">搜索记录</h3>
                <ul class="search-history-list" v-if="globalSearchHistory.length > 0">
                    <li v-for="(item, index) of globalSearchHistory" :key="index" class="history-list-item"
                        @click="handlerHistoryClick(item)">{{ item }}</li>
                </ul>
                <span v-else class="no-history">
                    暂无搜索记录～
                </span>
            </div>
            <div class="search-res-list">
                <div v-if="resList.length == 0" class="res-empty">暂无数据</div>
                <ul v-else class="res-list">
                    <li v-for="(item, index) of resList" :key="index" class="res-list-item"
                        @click="handlerResClick(item)">
                        <span>
                            {{ item.title }}
                        </span>
                        <span v-if="item.children && item.children.length > 0" class="icon-wrapper">
                            <el-tooltip class="item" effect="dark" placement="top-start">
                                <div slot="content">该搜索结果为菜单分类，不能点击<br />跳转，请搜索该菜单下的应用功能。</div>
                                <i class="el-icon-warning"></i>
                            </el-tooltip>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </transition>
</template>

<script>
import { getFrameUrl } from "@/xkUI/libs/menu.js";
import * as dd from "dingtalk-jsapi";
export default {
    name: "HeadSearchRes",
    props: {
        globalSearch: String,
        showHeadSearchRes: Boolean,
        placement: Object,
        resList: Array,
        userRouters: Array,
        originRouters: Array
    },
    data() {
        return {
            historyList: [],
            inDingTalk: false, // 是否是钉钉环境
        }
    },
    created() {
        this.inDingTalk = dd.env.platform !== "notInDingTalk";
        console.log('this.inDingTalk当前登录环境是否是钉钉', this.inDingTalk);
    },
    computed: {
        globalSearchHistory() {
            try {
                if (localStorage.globalSearchHistory) {
                    let historyOriginList = localStorage.globalSearchHistory.split(",");
                    this.historyList = historyOriginList.length > 6 ? historyOriginList.slice(0, 6) : historyOriginList;
                    return this.historyList;
                } else {
                    return [];
                }
            } catch (e) { }
        },
    },
    methods: {
        /**
         * @Description: 点击跳转
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-13 08:25:41
         * @param {*} item
         */
        handlerResClick(item) {
            console.log("[handlerResClick]item", item)
            let selRouter = this.getRouterTreeSel(this.userRouters, item)[0]
            let isParent = this.checkHasChild(selRouter);
            console.log(isParent, 'isParent')
            if (isParent) {
                // this.$message.info('请选择页面～')
            } else {
                let historyIndex = this.historyList.indexOf(item.title)
                if (historyIndex > -1) { // 历史记录中找到了调换位置到第一个
                    this.historyList.splice(historyIndex, 1)
                }
                this.historyList.unshift(item.title)
                localStorage.globalSearchHistory = this.historyList.join(',');
                // 如果是外链跳转
                if (item.isFrame === 1 && item.linkJump != 2) {
                    this.handleFrameUrl(item)
                }
                console.log(item, 'item-item-item')
                this.$router.push({ name: item.name })
                this.close()
            }
        },
        // 跳转外链
        handleFrameUrl(tar) {
            if (tar.frameUrl) {
                let frameUrl = getFrameUrl(tar)
                let promise = new Promise((resolve, reject) => {
                    resolve(frameUrl)
                })
                promise.then((res) => {
                    if (this.inDingTalk) {
                        // 是钉钉环境跳转
                        let urlEncodeedParam = encodeURIComponent(res)
                        let newUrl = `https://applink.dingtalk.com/page/link?url=${urlEncodeedParam}&target=workbench`;
                        let a = document.createElement("a")
                        a.href = newUrl
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                    } else {

                        // 如果不是钉钉环境
                        const _window = window.open();
                        console.log(res, '跳转地址')
                        if (_window) {
                            _window.location.href = res
                        } else {
                            const a = document.createElement("a")
                            a.href = res
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                        }
                    }
                })
            } else {
                this.$router.push({
                    name: 'Building',
                })
            }
        },
        /**
         * @Description: 历史记录跳转
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-13 10:44:48
         */
        handlerHistoryClick(item) {
            // console.log("[handlerHistoryClick]", item, this.userRouters, this.originRouters)
            let routerInfo = this.originRouters.find((subItem) => {
                return item === subItem.title;
            })
            // 如果是外链跳转
            if (routerInfo.isFrame === 1 && routerInfo.linkJump != 2) {
                this.handleFrameUrl(routerInfo)
            }
            let routerName = routerInfo.name;
            this.$router.push({
                name: routerName
            })
            // 调换位置到第一个
            this.historyList.splice(this.historyList.indexOf(item), 1)
            this.historyList.unshift(item)
            localStorage.globalSearchHistory = this.historyList.join(',');
            this.close()
        },
        /**
         * @Description: 查看选中数据类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-13 08:26:02
         * @param {*} userRouters
         * @param {*} key
         */
        getRouterTreeSel(userRouters, key) {
            let ar1 = [];
            userRouters.map((item) => {
                if (key.name === item.name) {
                    ar1.push(item)
                };

                if (item.children && item.children.length > 0) {
                    let item1 = this.getRouterTreeSel(item.children, key)
                    if (item1.length > 0) {
                        ar1 = [...item1]
                    }
                };
            })

            return ar1;
        },
        checkHasChild(obj) {
            return 'children' in obj && (obj.children && obj.children.length > 0);
        },
        /**
         * @Description: 滑走了
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-13 08:26:29
         */
        inputBlur() {
            setTimeout(() => {
                this.close();
            }, 200)
        },
        /**
         * @Description: 鼠标滑走
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-13 10:49:40
         */
        handlerMouseleave() {
            console.log('handlerMouseleave')
        }
    },
    watch: {
        resList: {
            handler(newVal) {
                // console.log(newVal,'newVal-list')
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.head-search-res {
    width: 272px;
    height: 318px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    position: fixed;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px #e1e3e6;
    border-radius: 8px;
    box-sizing: border-box;

    .search-title {
        font-size: 14px;
        line-height: 44px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2b2f33;
    }

    .search-history-wrapper {
        box-sizing: border-box;
        padding: 0 16px;

        .search-history-list {
            margin: 0;
            padding: 0;
            height: auto;
            border-bottom: 1px solid #e1e3e6;

            .history-list-item {
                display: inline-block;
                border: 1px solid #e1e3e6;
                box-sizing: border-box;
                padding: 3px 11px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #6d7073;
                border-radius: 12px;
                margin-bottom: 8px;
                margin-right: 8px;
                cursor: pointer;
            }

            .history-list-item:hover {
                color: #3C7FFF;
                border-color: #3C7FFF;
            }
        }

        .no-history {
            height: auto;
            display: block;
            font-size: 12px;
            line-height: 32px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6d7073;
            border-bottom: 1px solid #e1e3e6;
        }
    }

    .search-res-list {
        width: 100%;
        flex: 1;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;

        .res-empty {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .res-list {
            height: 100%;
            padding: 0;
            margin: 0;
            overflow-y: auto;

            .res-list-item {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2B2F33;
                box-sizing: border-box;
                padding: 12px 20px;
                cursor: pointer;

                .icon-wrapper {
                    float: right;

                    .el-icon-warning {
                        color: #595959;
                    }

                    .el-icon-warning:hover {
                        color: #3C7FFF;
                    }
                }
            }

            .res-list-item:hover {
                background-color: #F0F0F0;
            }
        }

    }
}
</style>
