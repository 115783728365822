import Vue from 'vue';
import router from '@/router/index.js'

export function createdSearchRes (Component, props) {
    const vm = new Vue({
        router,
        render(h) {
            return h(Component, {props})
        }
    }).$mount()

    document.body.appendChild(vm.$el);
    const comp = vm.$children[0];

    comp.close = () => {
        vm.$destroy()
        document.getElementById('J_head-search-res') && vm.$el && document.body && document.body.removeChild(vm.$el)

    };
    return comp;
}
