<template>
    <div
        class="header-logo">
        <el-image
            v-if="$store.state.schoolLogo"
            :src="$store.state.schoolLogo"
            class="logo-img"
            alt="logo"
            style="width: 40px; height: 40px; flex-shrink: 0"
            @click="handlerHomeClick"
        ></el-image>
        <transition name="el-fade-in">
            <span
                style="padding-left: 10px"
                class="header-title"
                @click="handlerHomeClick"
                >{{ $store.state.schoolName || "" }}</span
            >
        </transition>
    </div>
</template>
<script>

export default {
    computed: {
        defaultImg() {
            return require("@/assets/images/default-logo.png");
        },
        localStorageName() {
            return window.localStorage.getItem("schoolName");
        },
    },
    methods: {
        /**
         * @Description: 首页
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-13 11:36:27
         */
        handlerHomeClick() {
            this.$router.push({
                name: "FirstPage",
            });
        },
    },
};
</script>
<style lang="scss" scoped>

@media screen and (min-width: 1441px) {
    .header-logo {
        width: 274px;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        overflow: hidden;
        margin-right: auto;
        flex-shrink: 0;
       .logo-img {
           width: 42px;
           text-align: center;
           cursor: pointer;
           font-size: 12px;
       }
       .header-title {
           max-width: 224px;
           display: block;
           font-size: 18px;
           font-family: Microsoft YaHei;
           font-weight: bold;
           color: var(--header-text);
           cursor: pointer;
           overflow: hidden;
           text-overflow: ellipsis;
           white-space: nowrap;
       }
   }
}

@media screen and (max-width: 1440px) {
    .header-logo {
        width: 274px;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        overflow: hidden;
        margin-right: auto;
        flex-shrink: 0;
        .logo-img {
            width: 42px;
            text-align: center;
            cursor: pointer;
            font-size: 12px;
        }
        .header-title {
            max-width: 224px;
            display: block;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
</style>
